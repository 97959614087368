@import "_shame.scss";
/*PageComponent*/

body.events {
  width: auto;
  height: auto;
  background: unset;
  background-color: #f2f2f2;

  .abep--page {
    font-family: Verdana, sans-serif;
    margin-bottom: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    @media only screen and (max-width: 995px) {
      flex-direction: column;
    }

    @include abep--pageheader;
    .abep--pageheader {
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 14px;
      }
    }
    .abep--event {
      width: 100%;
      max-width: 600px;
      margin-right: 20px;
      @media only screen and (max-width: 995px) {
        max-width: 800px;
        align-self: center;
        margin-right: unset;
        margin-bottom: 1.3em;
      }
    }

    .abew--wrapper {
      @media only screen and (max-width: 995px) {
        max-width: 100%;
        align-self: center;
      }
      .abep--pageheader {
        display: none;
      }
    }

    .abew--widget {
      max-width: 375px;
      box-shadow: $box-shadow;
      @media only screen and (max-width: 995px) {
        width: 100%;
        max-width: 800px;
        align-self: center;
      }
      .abew--karta {
        order: unset;
        height: 200px;
        > div,
        .mapboxgl-map {
          height: 200px !important; // important because of maptiler inline css
        }
      }
      .abew--body {
        width: unset;
        order: unset;
      }
      .abew--visapakarta {
        display: flex;
      }

      &.hide-map .abew--karta {
        @media only screen and (max-width: 767px) {
          display: block;
        }
      }

      .abew--eventboxes-container {
        height: 268px;
        overflow: auto;
      }
    }
    .abep--eventheader {
      display: flex;
      background: white;
      max-width: 100%;
      box-shadow: $box-shadow;
      flex-direction: column;
      padding-bottom: 10px;

      h1 {
        font-weight: bold;
        font-size: 46px;
        line-height: 1.26em;
        letter-spacing: -0.0565em;
        margin: 6px 14px 0;

        @media only screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .abep--organizer-logo {
      padding: 5px 16px 0;
      display: flex;
      font-size: 14px;
      line-height: 1em;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      align-items: center;
      img {
        height: 31px;
        width: auto;
        margin-right: 8px;
      }
    }
    .abep--eventlocation {
      text-align: center;
      margin-bottom: 15px;
      p {
        font-size: 16px;
      }
      .icon {
        background-size: 13px auto;
        background-position: 0 0;
        display: inline-block;
        margin-bottom: -10px;
        margin-right: 0;
        padding-left: 17px;
      }
    }
    .abep--eventdetails {
      background: white;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      padding: 16px;
      box-shadow: $box-shadow;
    }
    .abep--eventtime {
      display: flex;
      justify-content: center;
      p {
        font-size: 12px;
        text-align: center;
        padding: 0 15px;
        min-height: 41px;
        border-right: 1px solid #efefef;
        &:last-of-type {
          border-right: 0;
        }
      }
      strong {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
      }
    }
    .abep--event-buttons {
      display: flex;
      justify-content: center;

      .button {
        width: 33%;
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        .button {
          align-self: center;
          width: 100%;
          margin: 0 0 10px;
          max-width: 320px;
        }
      }
    }
    .abep--eventcategory {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    .abep--categoryicon {
      position: absolute;
      top: 0;
      right: 0;
      height: 57px;
      width: 57px;
      margin-right: 16px;
      margin-top: 0;
      &.abep--categoryicon-noje {
        background: url("/evenemang/uppsala/static/active_state/noje.svg") center center no-repeat;
        background-size: 57px 57px;
      }
    }
    .abep--eventpricelink {
      display: flex;
      margin-left: 16px;
      margin-top: 16px;
    }

    .abep--eventpricelink-amount,
    .abep--eventpricelink-site {
      font-family: Verdana;
      font-size: 14px;
      margin: right;
    }

    .abep--eventpricelink-amount {
      margin-right: 31px;
    }

    .abep--eventpricelink-amount span,
    .abep--eventpricelink-site span {
      font-size: 18px;
      font-weight: bold;
      display: block;
    }
    .abep--eventpricelink-site a {
      color: #0078bd;
      text-decoration: none;
      transition: all 0.2s ease;
      &:hover {
        transition: all 0.2s ease;
        text-decoration: underline;
      }
    }

    .abep--eventtext {
      background: white;
      margin-top: 12px;
      padding: 16px;
      box-shadow: $box-shadow;
      font-family: arial;
      font-size: 16px;

      p {
        margin-bottom: 1em;
      }
      iframe {
        display: none;
      }
    }
    .abep--organizer-url {
      a {
        font-weight: bold;
      }
    }

    .abep--readmore {
      height: 40px;
      width: calc(100% - 32px);
      margin: 16px 16px 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      font-family: Helvetica Neue;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        transition: all 0.2s ease;
        color: black;
      }
    }

    .abep--ad {
      display: flex;
      flex-direction: column;
      padding: 20px 10px 10px;
      width: 100%;
      min-height: 104px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      text-align: center;
      text-decoration: none;
      justify-content: center;
      background: #dd2b30 url("/evenemang/uppsala/static/bg-ad.svg") right bottom no-repeat;
      background-size: 100%;
      margin-top: 1em;

      .logos {
        margin-top: 12px;
        img {
          height: 24px;
        }
      }
    }
  }
}
