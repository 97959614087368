@import "_shame.scss";

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slide-down {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

.abew--widget {
  font-family: Verdana, sans-serif;
  background-color: white;

  .abew--widgetHeader {
    background-color: #000000;
    display: flex;
    // background: #dd2a30 url("/evenemang/uppsala/static/header.svg") 0 0 repeat-x;
    height: 48px;
    display: flex;
    align-items: center;
    img {
      width: 135px;
      height: auto;
      margin-bottom: 0;
      margin-left: 18px;
    }
    @media only screen and (min-width: 768px) {
      left: 0;
      right: 0;
      top: 54px;
      width: 100%;
    }
  }
  .abew--visakarta,
  .abew--visapakarta {
    display: none;
    text-transform: uppercase;
    background: url("/evenemang/uppsala/static/karta.svg") center right no-repeat;
    padding-right: 18px;
    background-size: 17px;
    margin-left: auto;
    margin-right: 16px;
    align-items: center;
    a {
      font-family: arial;
      font-size: 12px;
      color: white;
    }
  }
  .abew--visakarta {
    display: none;
  }
  .abew--karta {
    max-width: 100%;
    height: 200px;
    background-color: lightgrey;

    > div,
    .mapboxgl-map {
      height: 200px;
      width: 100vw;
    }

    @media only screen and (min-width: 768px) {
      flex: 1;
      min-width: 30%;
      height: calc(100vh - 93px);
      background: linen;
      order: 3;
      > div,
      .mapboxgl-map {
        height: calc(100vh - 93px) !important; // important because of maptiler inline css
        width: unset;
      }
    }
  }
  .abew--body {
    .loader-container {
      margin-top: 16px;
      transition: all 0.5s ease;
      &.hide {
        transition: all 0.5s ease;
      }
    }
  }
  .abew--title {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 20px;
  }

  /*filter*/
  .abew--filter {
    display: flex;
    flex-shrink: none;
  }
  .abew--city {
    margin-top: 20px;
    margin-left: 15px;
    flex: 4 1;
    height: 35px;
    display: flex;
    align-items: center;
    max-width: 400px;
  }

  .abew--time {
    flex: 3 1;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 15px;
    height: 35px;
    display: flex;
    align-items: center;
    max-width: 200px;
    position: relative;
    .react-datepicker-wrapper .react-datepicker__input-container input {
      height: 33px;
      border-radius: 10px;
      border: 1px solid lightgray;
      padding: 15px;
    }
  }
  .abew--city,
  .abew--time {
    .abew--select {
      font-size: 16px;
      font-family: Arial;
      border: none;
      outline: 0px;
      -webkit-appearance: none;
      border-radius: 100px;
      width: 100%;
      background-image: url("/evenemang/uppsala/static/UI/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center right 18px;
      background-color: #fafafa;
      border: solid 1px #d2d2d2;
    }
  }

  .abew--city.active,
  .abew--time.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .abew-availableOptions {
    position: absolute;
    display: block;
    top: 30px;
    background: #fff;
    list-style-type: none;
    border: 1px solid #ccc;
    border-top: 0px solid transparent;
    width: calc(100% + 2px);
    margin: 0 -1px;
    font-size: 16px;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    z-index: 2;

    li {
      line-height: 31px;
      border-top: 1px solid #dcdcdc;
      padding: 0 12px;
      color: #222222;
      font-size: 14px;
      font-family: Arial;
    }
  }

  .abew--city {
    img {
      margin-right: 10px;
    }
  }

  /*category*/

  .abew--categories {
    display: flex;
    padding: 0;
    position: relative;
  }

  .abew--categories-scrollWrapper {
    overflow: auto;
    max-width: 100%;
    display: flex;
    -webkit-overflow-scrolling: touch;
  }

  .abew--categories-fadeStart {
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 80%);
  }

  .abew--categories-fadeEnd {
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 35px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
  }

  .abew--category {
    display: flex;
    flex-shrink: none;
    margin-right: 6px;

    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
    &:hover {
      cursor: pointer;
    }
    &.active:hover {
      cursor: default;
    }

    .abew--type {
      //      border: 2px solid #efefef;
      background-position: 0 0;
    }
    &.active p {
      font-weight: bold;
    }

    &.alla {
      .abew--type {
        background-image: url("/evenemang/uppsala/static/default_state/alla.svg");
      }
      &:hover .abew--type {
        background-image: url("/evenemang/uppsala/static/active_state/alla.svg");
      }
      &.active {
        .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/alla.svg");
        }
        ~ .noje .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/noje.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/noje.svg");
          }
        }
        ~ .musik .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/musik.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/musik.svg");
          }
        }
        ~ .kultur .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/kultur.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/kultur.svg");
          }
        }
        ~ .konst .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/konst.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/konst.svg");
          }
        }
        ~ .barn .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/barn.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/barn.svg");
          }
        }
        ~ .teater .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/teater.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/teater.svg");
          }
        }
        ~ .klubb .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/klubb.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/klubb.svg");
          }
        }
        ~ .show .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/show.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/show.svg");
          }
        }
        ~ .forelasning .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/forelasning.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/forelasning.svg");
          }
        }
        ~ .festival .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/festival.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/festival.svg");
          }
        }
        ~ .ovrigt .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/ovrigt.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/ovrigt.svg");
          }
        }
        ~ .sport .abew--type {
          background-image: url("/evenemang/uppsala/static/hover_state/sport.svg");
          &:hover {
            background-image: url("/evenemang/uppsala/static/active_state/sport.svg");
          }
        }
      }
    }

    &.noje .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/noje.svg");
    }
    &.noje:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/noje.svg");
    }
    &.noje.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/noje.svg");
    }

    &.musik .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/musik.svg");
    }
    &.musik:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/musik.svg");
    }
    &.musik.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/musik.svg");
    }

    &.kultur .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/kultur.svg");
    }
    &.kultur:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/kultur.svg");
    }
    &.kultur.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/kultur.svg");
    }

    &.konst .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/konst.svg");
    }
    &.konst:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/konst.svg");
    }
    &.konst.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/konst.svg");
    }

    &.barn .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/barn.svg");
    }
    &.barn:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/barn.svg");
    }
    &.barn.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/barn.svg");
    }

    &.teater .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/teater.svg");
    }
    &.teater:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/teater.svg");
    }
    &.teater.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/teater.svg");
    }

    &.klubb .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/klubb.svg");
    }
    &.klubb:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/klubb.svg");
    }
    &.klubb.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/klubb.svg");
    }

    &.show .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/show.svg");
    }
    &.show:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/show.svg");
    }
    &.show.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/show.svg");
    }

    &.forelasning .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/forelasning.svg");
    }
    &.forelasning:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/forelasning.svg");
    }
    &.forelasning.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/forelasning.svg");
    }

    &.festival .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/festival.svg");
    }
    &.festival:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/festival.svg");
    }
    &.festival.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/festival.svg");
    }

    &.ovrigt .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/ovrigt.svg");
    }
    &.ovrigt:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/ovrigt.svg");
    }
    &.ovrigt.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/ovrigt.svg");
    }

    &.sport .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/sport.svg");
    }
    &.sport:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/sport.svg");
    }
    &.sport.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/sport.svg");
    }
  }
  .abew--topic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 14px;

    p {
      font-size: 10px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .abew--type {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: transparent center center no-repeat;
    background-size: 100% 100%;

    &:hover {
      transition: all 0.15s ease;
    }

    &.active,
    &.active:hover {
      background-color: lightblue;
    }
  }
  .abew--group {
    // Is this obsolete? KA
    width: 60px;
    height: 100px;
    text-align: center;
    display: flex;
  }

  /*event*/
  .abew--fact {
    display: flex;
    flex-shrink: none;
    justify-content: space-between;
    margin: 10px 0 6px;
  }
  .abew--day {
    flex: 1;
  }
  .abew--amount {
    flex: 1;
    text-align: right;
  }
  .abew--amount p {
    margin-right: 15px;
    font-size: 12px;
    color: gray;
  }
  .abew--day p {
    margin-left: 16px;
    text-transform: uppercase;
    font-size: 12px;
  }

  /*eventbox*/
  .abew--eventboxes-container {
    //      overflow: scroll;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0.352764) 0%, #ffffff 1);

    // mask-image: linear-gradient(to top, transparent 0, rgba(0, 0, 0, 1));
    //      mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    // mask-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 21%, rgba(0,0,0,1) 100%);
    // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(204,204,204,0) 20%, rgba(0,0,0,0) 100%); /* w3c */

    mask-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, rgba(0, 0, 0, 1)),
      color-stop(0.7, rgba(0, 0, 0, 1)),
      color-stop(1, rgba(0, 0, 0, 0))
    );

    & + .spinner {
      top: 5px;
    }
  }
  .no-events-text {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 5.5em auto 1em;
    text-align: center;
  }

  .no-events-reset-link {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0 auto;
    color: #0078bd;

    &:hover {
      text-decoration: underline;
    }
  }
  .abew--eventboxes {
    padding: 0 15px 0 16px;
    //    text-transform: uppercase;
    -webkit-animation: slide-up 0.4s ease-out;
    -moz-animation: slide-down 0.3s ease-out;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .abew--eventbox {
    height: 62px;
    max-width: 100%;
    background-color: #f7f7fa;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }

  .abew--date,
  .abew--info {
    position: relative;
    width: 100%;
    flex-shrink: none;
  }

  .abew--date {
    .abew--arrow {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100px;
      background: #f7f7fa;
      background: linear-gradient(to right, rgba(247, 247, 250, 0) 0%, rgba(247, 247, 250, 1) 50%);
      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100px;
        background: url("/evenemang/uppsala/static/UI/link-arrow.svg");
        background-repeat: no-repeat;
        background-position: center right 18px;
      }
    }
  }

  .abew--eventbox {
    border-left: 4px #e1e6eb solid;
  }
  .abew--date p {
    font-size: 12px;
    margin-left: 14px;
  }
  .abew--info p {
    font-size: 12px;
    margin: 0;
    margin-left: 14px;
    white-space: nowrap;
  }
  .abew--info h2 {
    font-size: 14px;
    margin: 0;
    margin-left: 14px;
    white-space: nowrap;
  }
  /*.abew--date,
  .abew--info,
  .abew--rightarrow {
    flex: 1;
  }*/
  .abew--info {
    height: 35px;
  }
  .abew--widgetFooter {
    cursor: pointer;
    text-transform: uppercase;
    height: 50px;
    width: 100%;
    margin-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: $box-shadow;
    font-family: Helvetica Neue;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: rgba(0, 0, 0, 0.8);
      transition: all 0.15s ease;
    }
    .spinner {
      margin: 0;
      width: 40px;
      margin-left: 48%;
    }
  }

  /*MapIcons*/
  .mapIconDefault {
    /* This is just for the internal icon library */
    display: flex;

    & > * {
      margin: 10px;
    }
  }

  .abew--mapIcon {
    width: 40px;
    height: 45px;
    background-size: 100% 100%;
  }

  .abew--mapIcon-noje {
    background-image: url("/evenemang/uppsala/static/map_default/noje.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/noje.svg");
    }
  }
  .abew--mapIcon-musik {
    background-image: url("/evenemang/uppsala/static/map_default/musik.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/musik.svg");
    }
  }
  .abew--mapIcon-kultur {
    background-image: url("/evenemang/uppsala/static/map_default/kultur.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/kultur.svg");
    }
  }
  .abew--mapIcon-konst {
    background-image: url("/evenemang/uppsala/static/map_default/konst.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/konst.svg");
    }
  }
  .abew--mapIcon-barn {
    background-image: url("/evenemang/uppsala/static/map_default/barn.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/barn.svg");
    }
  }
  .abew--mapIcon-teater {
    background-image: url("/evenemang/uppsala/static/map_default/teater.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/teater.svg");
    }
  }
  .abew--mapIcon-klubb {
    background-image: url("/evenemang/uppsala/static/map_default/klubb.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/klubb.svg");
    }
  }
  .abew--mapIcon-show {
    background-image: url("/evenemang/uppsala/static/map_default/show.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/show.svg");
    }
  }
  .abew--mapIcon-forelasning {
    background-image: url("/evenemang/uppsala/static/map_default/forelasning.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/forelasning.svg");
    }
  }
  .abew--mapIcon-festival {
    background-image: url("/evenemang/uppsala/static/map_default/festival.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/festival.svg");
    }
  }
  .abew--mapIcon-ovrigt {
    background-image: url("/evenemang/uppsala/static/map_default/ovrigt.svg");
    &:active {
      background-image: url("/evenemang/uppsala/static/map_active/ovrigt.svg");
    }
  }

  // Map styles

  .mapboxgl-popup {
    @media screen and (max-width: 425px) {
      max-width: 300px;
    }

    /*
      @media screen and (max-width: 375px) {
        max-width: 215px;
      }
      */
  }
  .alla .abew--eventbox {
    border-left-color: #e1e6eb;
  }
  .konst .abew--eventbox {
    border-left-color: #2fc684;
  }
  .musik .abew--eventbox {
    border-left-color: #3398db;
  }
  .kultur .abew--eventbox {
    border-left-color: #6533db;
  }
  .noje .abew--eventbox {
    border-left-color: #a9127c;
  }
  .barn .abew--eventbox {
    border-left-color: #e645a1;
  }
  .ovrigt .abew--eventbox {
    border-left-color: #ee6815;
  }
  .teater .abew--eventbox{
    border-left-color: #000000;
  }
  .festival .abew--eventbox{
    border-left-color: #A9127C;
  }
  .klubb .abew--eventbox{
    border-left-color: #271AED;
  }

  .mapboxgl-popup-content {
    box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.451759);
    padding: 0;

    a {
      color: black;
    }
    .abew--eventbox {
      font-family: Verdana, sans-serif;
      line-height: 1.35em;
      background-color: white;
      margin-bottom: 0;
      .abew--date .abew--arrow {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
      }
    }
    a {
      text-decoration: none;
    }
  }
  .mapboxgl-marker {
    height: 80px;
    width: 80px;
    background: transparent url("/evenemang/uppsala/static/map_default/ovrigt.svg") no-repeat center bottom; // Mockup image for all categories
    background-size: 55px auto;
    border: none;

    img {
      display: none;
    }

    // Add the corresponding class to each .mapboxgl-marker
    &.barn {
      background-image: url("/evenemang/uppsala/static/map_default/barn.svg");
    }
    &.barn-active {
      background-image: url("/evenemang/uppsala/static/map_active/barn.svg");
    }
    &.konst {
      background-image: url("/evenemang/uppsala/static/map_default/konst.svg");
    }
    &.konst-active {
      background-image: url("/evenemang/uppsala/static/map_active/konst.svg");
    }
    &.kultur {
      background-image: url("/evenemang/uppsala/static/map_default/kultur.svg");
    }
    &.kultur-active {
      background-image: url("/evenemang/uppsala/static/map_active/kultur.svg");
    }
    &.musik {
      background-image: url("/evenemang/uppsala/static/map_default/musik.svg");
    }
    &.musik-active {
      background-image: url("/evenemang/uppsala/static/map_active/musik.svg");
    }
    &.noje {
      background-image: url("/evenemang/uppsala/static/map_default/noje.svg");
    }
    &.noje-active {
      background-image: url("/evenemang/uppsala/static/map_active/noje.svg");
    }
    &.ovrigt {
      background-image: url("/evenemang/uppsala/static/map_default/ovrigt.svg");
    }
    &.ovrigt-active {
      background-image: url("/evenemang/uppsala/static/map_active/ovrigt.svg");
    }
    &.klubb {
      background-image: url("/evenemang/uppsala/static/map_default/klubb.svg");
    }
    &.klubb-active {
      background-image: url("/evenemang/uppsala/static/map_active/klubb.svg");
    }
    &.teater {
      background-image: url("/evenemang/uppsala/static/map_default/teater.svg");
    }
    &.teater-active {
      background-image: url("/evenemang/uppsala/static/map_active/teater.svg");
    }
    &.festival {
      background-image: url("/evenemang/uppsala/static/map_default/festival.svg");
    }
    &.festival-active {
      background-image: url("/evenemang/uppsala/static/map_active/festival.svg");
    }
    &.sport {
      background-image: url("/evenemang/uppsala/static/map_default/sport.svg");
    }
    &.sport-active {
      background-image: url("/evenemang/uppsala/static/map_active/sport.svg");
    }
  }
}

// Select styles

/* class applies to select element itself, not a wrapper element */
body.events {
  .abew--select {
    display: block;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%; /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    //  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
    */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;
  }
  /* Hide arrow icon in IE browsers */
  .abew--select::-ms-expand {
    display: none;
  }
  /* Hover style */
  .abew--select:hover {
    border-color: #888;
    transition: all 0.15s ease;
  }
  /* Focus style */
  .abew--select:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    //  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    //  box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  /* Set options to normal weight */
  .abew--select option {
    font-weight: normal;
  }

  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir="rtl"] .abew--select,
  :root:lang(ar) .abew--select,
  :root:lang(iw) .abew--select {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }

  /* Disabled styles */
  .abew--select:disabled,
  .abew--select[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  }

  .abew--select:disabled:hover,
  .abew--select[aria-disabled="true"] {
    transition: all 0.15s ease;
    border-color: #aaa;
  }
}

.spinner {
  position: relative;
  bottom: 10px;
  width: 40px;
  margin-left: calc(50% - 20px);
}

// If widget is embedded
.embedded {
  .abep--pageheader {
    display: none;
  }
  .abew--widget {
    margin: 0 auto;
    .abew--eventboxes-container {
      max-height: 210px; // visible map
    }
    &.hide-map .abew--eventboxes-container {
      max-height: 410px;
      height: 268px;
    }
    .abew--visapakarta {
      display: flex;
    }
    .abew--karta {
      display: none;
    }
  }
}

// If widget is not embedded
.not-embedded {
  @include abep--pageheader;

  body.events .abew--widget {
    .abew--eventboxes-container {
      height: calc(100vh - 355px);
    }
    @media only screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .abew--body {
    @media only screen and (min-width: 768px) {
      width: 40%;
      min-width: 320px;
      order: 2;
    }
  }
}
