body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border:1px solid gray;
  background-color:lightblue;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
  vertical-align: initial; }

html * {
  box-sizing: border-box; }

a,
a:visited,
a:active {
  color: #0078bd;
  text-decoration: none; }
  a:hover,
  a:visited:hover,
  a:active:hover {
    text-decoration: underline; }

loader-container {
  padding: 24px 0; }

.loader,
.loader:before,
.loader:after {
  border-radius: 100%;
  width: 1.3em;
  height: 1.3em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.loader {
  color: #dd2a30;
  font-size: 13px;
  margin: 0 auto;
  position: relative;
  top: -26px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0; }

.loader:before {
  left: -2em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 2em; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em; }
  40% {
    box-shadow: 0 2em 0 0; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em; }
  40% {
    box-shadow: 0 2em 0 0; } }

.hide {
  display: none !important; }

.abep--event-buttons {
  margin-top: 15px;
  margin-bottom: 5px; }

body .button {
  background: white;
  color: black;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  height: 48px;
  border: 1px solid #979797;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  padding: 0 15px;
  cursor: pointer;
  margin-right: 15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  body .button:last-child {
    margin-right: 0; }
  body .button:hover {
    opacity: 0.95; }

body .button-red {
  border-color: transparent;
  background: #57F099;
  color: white; }
  body .button-red .icon {
    -webkit-filter: invert(100%);
            filter: invert(100%); }

body .icon {
  background-size: 24px 24px;
  padding-left: 24px;
  height: 24px;
  margin-right: 7px;
  background-position: center center;
  background-repeat: no-repeat; }

body .icon__calendar {
  background-image: url("/evenemang/uppsala/static/icons/icon__add-event-to-calendar.svg"); }

body .icon__tickets {
  background-image: url("/evenemang/uppsala/static/icons/icon__tickets.svg"); }

body .icon__map-pin {
  background-image: url("/evenemang/uppsala/static/icons/icon__map-pin.svg"); }

body .calendar p {
  font-size: 14px; }

@media only screen and (max-width: 424px) {
  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; } }

@-webkit-keyframes slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

.abew--widget {
  font-family: Verdana, sans-serif;
  background-color: white;
  /*filter*/
  /*category*/
  /*event*/
  /*eventbox*/
  /*.abew--date,
  .abew--info,
  .abew--rightarrow {
    flex: 1;
  }*/
  /*MapIcons*/ }
  .abew--widget .abew--widgetHeader {
    background-color: #000000;
    display: flex;
    height: 48px;
    display: flex;
    align-items: center; }
    .abew--widget .abew--widgetHeader img {
      width: 135px;
      height: auto;
      margin-bottom: 0;
      margin-left: 18px; }
    @media only screen and (min-width: 768px) {
      .abew--widget .abew--widgetHeader {
        left: 0;
        right: 0;
        top: 54px;
        width: 100%; } }
  .abew--widget .abew--visakarta,
  .abew--widget .abew--visapakarta {
    display: none;
    text-transform: uppercase;
    background: url("/evenemang/uppsala/static/karta.svg") center right no-repeat;
    padding-right: 18px;
    background-size: 17px;
    margin-left: auto;
    margin-right: 16px;
    align-items: center; }
    .abew--widget .abew--visakarta a,
    .abew--widget .abew--visapakarta a {
      font-family: arial;
      font-size: 12px;
      color: white; }
  .abew--widget .abew--visakarta {
    display: none; }
  .abew--widget .abew--karta {
    max-width: 100%;
    height: 200px;
    background-color: lightgrey; }
    .abew--widget .abew--karta > div,
    .abew--widget .abew--karta .mapboxgl-map {
      height: 200px;
      width: 100vw; }
    @media only screen and (min-width: 768px) {
      .abew--widget .abew--karta {
        flex: 1 1;
        min-width: 30%;
        height: calc(100vh - 93px);
        background: linen;
        order: 3; }
        .abew--widget .abew--karta > div,
        .abew--widget .abew--karta .mapboxgl-map {
          height: calc(100vh - 93px) !important;
          width: unset; } }
  .abew--widget .abew--body .loader-container {
    margin-top: 16px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .abew--widget .abew--body .loader-container.hide {
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
  .abew--widget .abew--title {
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 20px; }
  .abew--widget .abew--filter {
    display: flex;
    flex-shrink: none; }
  .abew--widget .abew--city {
    margin-top: 20px;
    margin-left: 15px;
    flex: 4 1;
    height: 35px;
    display: flex;
    align-items: center;
    max-width: 400px; }
  .abew--widget .abew--time {
    flex: 3 1;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 15px;
    height: 35px;
    display: flex;
    align-items: center;
    max-width: 200px;
    position: relative; }
    .abew--widget .abew--time .react-datepicker-wrapper .react-datepicker__input-container input {
      height: 33px;
      border-radius: 10px;
      border: 1px solid lightgray;
      padding: 15px; }
  .abew--widget .abew--city .abew--select,
  .abew--widget .abew--time .abew--select {
    font-size: 16px;
    font-family: Arial;
    border: none;
    outline: 0px;
    -webkit-appearance: none;
    border-radius: 100px;
    width: 100%;
    background-image: url("/evenemang/uppsala/static/UI/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center right 18px;
    background-color: #fafafa;
    border: solid 1px #d2d2d2; }
  .abew--widget .abew--city.active,
  .abew--widget .abew--time.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .abew--widget .abew-availableOptions {
    position: absolute;
    display: block;
    top: 30px;
    background: #fff;
    list-style-type: none;
    border: 1px solid #ccc;
    border-top: 0px solid transparent;
    width: calc(100% + 2px);
    margin: 0 -1px;
    font-size: 16px;
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
    z-index: 2; }
    .abew--widget .abew-availableOptions li {
      line-height: 31px;
      border-top: 1px solid #dcdcdc;
      padding: 0 12px;
      color: #222222;
      font-size: 14px;
      font-family: Arial; }
  .abew--widget .abew--city img {
    margin-right: 10px; }
  .abew--widget .abew--categories {
    display: flex;
    padding: 0;
    position: relative; }
  .abew--widget .abew--categories-scrollWrapper {
    overflow: auto;
    max-width: 100%;
    display: flex;
    -webkit-overflow-scrolling: touch; }
  .abew--widget .abew--categories-fadeStart {
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 35px;
    height: 100%;
    background: -webkit-gradient(linear, left top, right top, from(white), color-stop(80%, rgba(255, 255, 255, 0)));
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 80%); }
  .abew--widget .abew--categories-fadeEnd {
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 35px;
    height: 100%;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(80%, white));
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 80%); }
  .abew--widget .abew--category {
    display: flex;
    flex-shrink: none;
    margin-right: 6px; }
    .abew--widget .abew--category:first-child {
      padding-left: 15px; }
    .abew--widget .abew--category:last-child {
      padding-right: 15px; }
    .abew--widget .abew--category:hover {
      cursor: pointer; }
    .abew--widget .abew--category.active:hover {
      cursor: default; }
    .abew--widget .abew--category .abew--type {
      background-position: 0 0; }
    .abew--widget .abew--category.active p {
      font-weight: bold; }
    .abew--widget .abew--category.alla .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/alla.svg"); }
    .abew--widget .abew--category.alla:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/alla.svg"); }
    .abew--widget .abew--category.alla.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/alla.svg"); }
    .abew--widget .abew--category.alla.active ~ .noje .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/noje.svg"); }
      .abew--widget .abew--category.alla.active ~ .noje .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/noje.svg"); }
    .abew--widget .abew--category.alla.active ~ .musik .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/musik.svg"); }
      .abew--widget .abew--category.alla.active ~ .musik .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/musik.svg"); }
    .abew--widget .abew--category.alla.active ~ .kultur .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/kultur.svg"); }
      .abew--widget .abew--category.alla.active ~ .kultur .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/kultur.svg"); }
    .abew--widget .abew--category.alla.active ~ .konst .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/konst.svg"); }
      .abew--widget .abew--category.alla.active ~ .konst .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/konst.svg"); }
    .abew--widget .abew--category.alla.active ~ .barn .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/barn.svg"); }
      .abew--widget .abew--category.alla.active ~ .barn .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/barn.svg"); }
    .abew--widget .abew--category.alla.active ~ .teater .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/teater.svg"); }
      .abew--widget .abew--category.alla.active ~ .teater .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/teater.svg"); }
    .abew--widget .abew--category.alla.active ~ .klubb .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/klubb.svg"); }
      .abew--widget .abew--category.alla.active ~ .klubb .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/klubb.svg"); }
    .abew--widget .abew--category.alla.active ~ .show .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/show.svg"); }
      .abew--widget .abew--category.alla.active ~ .show .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/show.svg"); }
    .abew--widget .abew--category.alla.active ~ .forelasning .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/forelasning.svg"); }
      .abew--widget .abew--category.alla.active ~ .forelasning .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/forelasning.svg"); }
    .abew--widget .abew--category.alla.active ~ .festival .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/festival.svg"); }
      .abew--widget .abew--category.alla.active ~ .festival .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/festival.svg"); }
    .abew--widget .abew--category.alla.active ~ .ovrigt .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/ovrigt.svg"); }
      .abew--widget .abew--category.alla.active ~ .ovrigt .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/ovrigt.svg"); }
    .abew--widget .abew--category.alla.active ~ .sport .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/sport.svg"); }
      .abew--widget .abew--category.alla.active ~ .sport .abew--type:hover {
        background-image: url("/evenemang/uppsala/static/active_state/sport.svg"); }
    .abew--widget .abew--category.noje .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/noje.svg"); }
    .abew--widget .abew--category.noje:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/noje.svg"); }
    .abew--widget .abew--category.noje.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/noje.svg"); }
    .abew--widget .abew--category.musik .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/musik.svg"); }
    .abew--widget .abew--category.musik:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/musik.svg"); }
    .abew--widget .abew--category.musik.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/musik.svg"); }
    .abew--widget .abew--category.kultur .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/kultur.svg"); }
    .abew--widget .abew--category.kultur:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/kultur.svg"); }
    .abew--widget .abew--category.kultur.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/kultur.svg"); }
    .abew--widget .abew--category.konst .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/konst.svg"); }
    .abew--widget .abew--category.konst:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/konst.svg"); }
    .abew--widget .abew--category.konst.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/konst.svg"); }
    .abew--widget .abew--category.barn .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/barn.svg"); }
    .abew--widget .abew--category.barn:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/barn.svg"); }
    .abew--widget .abew--category.barn.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/barn.svg"); }
    .abew--widget .abew--category.teater .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/teater.svg"); }
    .abew--widget .abew--category.teater:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/teater.svg"); }
    .abew--widget .abew--category.teater.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/teater.svg"); }
    .abew--widget .abew--category.klubb .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/klubb.svg"); }
    .abew--widget .abew--category.klubb:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/klubb.svg"); }
    .abew--widget .abew--category.klubb.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/klubb.svg"); }
    .abew--widget .abew--category.show .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/show.svg"); }
    .abew--widget .abew--category.show:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/show.svg"); }
    .abew--widget .abew--category.show.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/show.svg"); }
    .abew--widget .abew--category.forelasning .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/forelasning.svg"); }
    .abew--widget .abew--category.forelasning:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/forelasning.svg"); }
    .abew--widget .abew--category.forelasning.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/forelasning.svg"); }
    .abew--widget .abew--category.festival .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/festival.svg"); }
    .abew--widget .abew--category.festival:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/festival.svg"); }
    .abew--widget .abew--category.festival.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/festival.svg"); }
    .abew--widget .abew--category.ovrigt .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/ovrigt.svg"); }
    .abew--widget .abew--category.ovrigt:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/ovrigt.svg"); }
    .abew--widget .abew--category.ovrigt.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/ovrigt.svg"); }
    .abew--widget .abew--category.sport .abew--type {
      background-image: url("/evenemang/uppsala/static/default_state/sport.svg"); }
    .abew--widget .abew--category.sport:hover .abew--type {
      background-image: url("/evenemang/uppsala/static/active_state/sport.svg"); }
    .abew--widget .abew--category.sport.active .abew--type {
      background-image: url("/evenemang/uppsala/static/hover_state/sport.svg"); }
  .abew--widget .abew--topic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 14px; }
    .abew--widget .abew--topic p {
      font-size: 10px;
      margin: 0 auto;
      text-align: center; }
  .abew--widget .abew--type {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    background: transparent center center no-repeat;
    background-size: 100% 100%; }
    .abew--widget .abew--type:hover {
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease; }
    .abew--widget .abew--type.active, .abew--widget .abew--type.active:hover {
      background-color: lightblue; }
  .abew--widget .abew--group {
    width: 60px;
    height: 100px;
    text-align: center;
    display: flex; }
  .abew--widget .abew--fact {
    display: flex;
    flex-shrink: none;
    justify-content: space-between;
    margin: 10px 0 6px; }
  .abew--widget .abew--day {
    flex: 1 1; }
  .abew--widget .abew--amount {
    flex: 1 1;
    text-align: right; }
  .abew--widget .abew--amount p {
    margin-right: 15px;
    font-size: 12px;
    color: gray; }
  .abew--widget .abew--day p {
    margin-left: 16px;
    text-transform: uppercase;
    font-size: 12px; }
  .abew--widget .abew--eventboxes-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.352764)), color-stop(1, #ffffff));
    -webkit-mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0.352764) 0%, #ffffff 1);
            mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.352764)), color-stop(1, #ffffff));
            mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0.352764) 0%, #ffffff 1);
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, black), color-stop(0.7, black), color-stop(1, rgba(0, 0, 0, 0)));
            mask-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, black), color-stop(0.7, black), color-stop(1, rgba(0, 0, 0, 0))); }
    .abew--widget .abew--eventboxes-container + .spinner {
      top: 5px; }
  .abew--widget .no-events-text {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 5.5em auto 1em;
    text-align: center; }
  .abew--widget .no-events-reset-link {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0 auto;
    color: #0078bd; }
    .abew--widget .no-events-reset-link:hover {
      text-decoration: underline; }
  .abew--widget .abew--eventboxes {
    padding: 0 15px 0 16px;
    -webkit-animation: slide-up 0.4s ease-out;
    -moz-animation: slide-down 0.3s ease-out; }
    .abew--widget .abew--eventboxes a {
      color: black;
      text-decoration: none; }
  .abew--widget .abew--eventbox {
    height: 62px;
    max-width: 100%;
    background-color: #f7f7fa;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    overflow: hidden; }
    .abew--widget .abew--eventbox:hover {
      text-decoration: underline; }
  .abew--widget .abew--date,
  .abew--widget .abew--info {
    position: relative;
    width: 100%;
    flex-shrink: none; }
  .abew--widget .abew--date .abew--arrow {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100px;
    background: #f7f7fa;
    background: -webkit-gradient(linear, left top, right top, from(rgba(247, 247, 250, 0)), color-stop(50%, #f7f7fa));
    background: linear-gradient(to right, rgba(247, 247, 250, 0) 0%, #f7f7fa 50%); }
    .abew--widget .abew--date .abew--arrow:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100px;
      background: url("/evenemang/uppsala/static/UI/link-arrow.svg");
      background-repeat: no-repeat;
      background-position: center right 18px; }
  .abew--widget .abew--eventbox {
    border-left: 4px #e1e6eb solid; }
  .abew--widget .abew--date p {
    font-size: 12px;
    margin-left: 14px; }
  .abew--widget .abew--info p {
    font-size: 12px;
    margin: 0;
    margin-left: 14px;
    white-space: nowrap; }
  .abew--widget .abew--info h2 {
    font-size: 14px;
    margin: 0;
    margin-left: 14px;
    white-space: nowrap; }
  .abew--widget .abew--info {
    height: 35px; }
  .abew--widget .abew--widgetFooter {
    cursor: pointer;
    text-transform: uppercase;
    height: 50px;
    width: 100%;
    margin-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921);
    font-family: Helvetica Neue;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center; }
    .abew--widget .abew--widgetFooter:hover {
      color: rgba(0, 0, 0, 0.8);
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease; }
    .abew--widget .abew--widgetFooter .spinner {
      margin: 0;
      width: 40px;
      margin-left: 48%; }
  .abew--widget .mapIconDefault {
    /* This is just for the internal icon library */
    display: flex; }
    .abew--widget .mapIconDefault > * {
      margin: 10px; }
  .abew--widget .abew--mapIcon {
    width: 40px;
    height: 45px;
    background-size: 100% 100%; }
  .abew--widget .abew--mapIcon-noje {
    background-image: url("/evenemang/uppsala/static/map_default/noje.svg"); }
    .abew--widget .abew--mapIcon-noje:active {
      background-image: url("/evenemang/uppsala/static/map_active/noje.svg"); }
  .abew--widget .abew--mapIcon-musik {
    background-image: url("/evenemang/uppsala/static/map_default/musik.svg"); }
    .abew--widget .abew--mapIcon-musik:active {
      background-image: url("/evenemang/uppsala/static/map_active/musik.svg"); }
  .abew--widget .abew--mapIcon-kultur {
    background-image: url("/evenemang/uppsala/static/map_default/kultur.svg"); }
    .abew--widget .abew--mapIcon-kultur:active {
      background-image: url("/evenemang/uppsala/static/map_active/kultur.svg"); }
  .abew--widget .abew--mapIcon-konst {
    background-image: url("/evenemang/uppsala/static/map_default/konst.svg"); }
    .abew--widget .abew--mapIcon-konst:active {
      background-image: url("/evenemang/uppsala/static/map_active/konst.svg"); }
  .abew--widget .abew--mapIcon-barn {
    background-image: url("/evenemang/uppsala/static/map_default/barn.svg"); }
    .abew--widget .abew--mapIcon-barn:active {
      background-image: url("/evenemang/uppsala/static/map_active/barn.svg"); }
  .abew--widget .abew--mapIcon-teater {
    background-image: url("/evenemang/uppsala/static/map_default/teater.svg"); }
    .abew--widget .abew--mapIcon-teater:active {
      background-image: url("/evenemang/uppsala/static/map_active/teater.svg"); }
  .abew--widget .abew--mapIcon-klubb {
    background-image: url("/evenemang/uppsala/static/map_default/klubb.svg"); }
    .abew--widget .abew--mapIcon-klubb:active {
      background-image: url("/evenemang/uppsala/static/map_active/klubb.svg"); }
  .abew--widget .abew--mapIcon-show {
    background-image: url("/evenemang/uppsala/static/map_default/show.svg"); }
    .abew--widget .abew--mapIcon-show:active {
      background-image: url("/evenemang/uppsala/static/map_active/show.svg"); }
  .abew--widget .abew--mapIcon-forelasning {
    background-image: url("/evenemang/uppsala/static/map_default/forelasning.svg"); }
    .abew--widget .abew--mapIcon-forelasning:active {
      background-image: url("/evenemang/uppsala/static/map_active/forelasning.svg"); }
  .abew--widget .abew--mapIcon-festival {
    background-image: url("/evenemang/uppsala/static/map_default/festival.svg"); }
    .abew--widget .abew--mapIcon-festival:active {
      background-image: url("/evenemang/uppsala/static/map_active/festival.svg"); }
  .abew--widget .abew--mapIcon-ovrigt {
    background-image: url("/evenemang/uppsala/static/map_default/ovrigt.svg"); }
    .abew--widget .abew--mapIcon-ovrigt:active {
      background-image: url("/evenemang/uppsala/static/map_active/ovrigt.svg"); }
  .abew--widget .mapboxgl-popup {
    /*
      @media screen and (max-width: 375px) {
        max-width: 215px;
      }
      */ }
    @media screen and (max-width: 425px) {
      .abew--widget .mapboxgl-popup {
        max-width: 300px; } }
  .abew--widget .alla .abew--eventbox {
    border-left-color: #e1e6eb; }
  .abew--widget .konst .abew--eventbox {
    border-left-color: #2fc684; }
  .abew--widget .musik .abew--eventbox {
    border-left-color: #3398db; }
  .abew--widget .kultur .abew--eventbox {
    border-left-color: #6533db; }
  .abew--widget .noje .abew--eventbox {
    border-left-color: #a9127c; }
  .abew--widget .barn .abew--eventbox {
    border-left-color: #e645a1; }
  .abew--widget .ovrigt .abew--eventbox {
    border-left-color: #ee6815; }
  .abew--widget .teater .abew--eventbox {
    border-left-color: #000000; }
  .abew--widget .festival .abew--eventbox {
    border-left-color: #A9127C; }
  .abew--widget .klubb .abew--eventbox {
    border-left-color: #271AED; }
  .abew--widget .mapboxgl-popup-content {
    box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.451759);
    padding: 0; }
    .abew--widget .mapboxgl-popup-content a {
      color: black; }
    .abew--widget .mapboxgl-popup-content .abew--eventbox {
      font-family: Verdana, sans-serif;
      line-height: 1.35em;
      background-color: white;
      margin-bottom: 0; }
      .abew--widget .mapboxgl-popup-content .abew--eventbox .abew--date .abew--arrow {
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(50%, #ffffff));
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 50%); }
    .abew--widget .mapboxgl-popup-content a {
      text-decoration: none; }
  .abew--widget .mapboxgl-marker {
    height: 80px;
    width: 80px;
    background: transparent url("/evenemang/uppsala/static/map_default/ovrigt.svg") no-repeat center bottom;
    background-size: 55px auto;
    border: none; }
    .abew--widget .mapboxgl-marker img {
      display: none; }
    .abew--widget .mapboxgl-marker.barn {
      background-image: url("/evenemang/uppsala/static/map_default/barn.svg"); }
    .abew--widget .mapboxgl-marker.barn-active {
      background-image: url("/evenemang/uppsala/static/map_active/barn.svg"); }
    .abew--widget .mapboxgl-marker.konst {
      background-image: url("/evenemang/uppsala/static/map_default/konst.svg"); }
    .abew--widget .mapboxgl-marker.konst-active {
      background-image: url("/evenemang/uppsala/static/map_active/konst.svg"); }
    .abew--widget .mapboxgl-marker.kultur {
      background-image: url("/evenemang/uppsala/static/map_default/kultur.svg"); }
    .abew--widget .mapboxgl-marker.kultur-active {
      background-image: url("/evenemang/uppsala/static/map_active/kultur.svg"); }
    .abew--widget .mapboxgl-marker.musik {
      background-image: url("/evenemang/uppsala/static/map_default/musik.svg"); }
    .abew--widget .mapboxgl-marker.musik-active {
      background-image: url("/evenemang/uppsala/static/map_active/musik.svg"); }
    .abew--widget .mapboxgl-marker.noje {
      background-image: url("/evenemang/uppsala/static/map_default/noje.svg"); }
    .abew--widget .mapboxgl-marker.noje-active {
      background-image: url("/evenemang/uppsala/static/map_active/noje.svg"); }
    .abew--widget .mapboxgl-marker.ovrigt {
      background-image: url("/evenemang/uppsala/static/map_default/ovrigt.svg"); }
    .abew--widget .mapboxgl-marker.ovrigt-active {
      background-image: url("/evenemang/uppsala/static/map_active/ovrigt.svg"); }
    .abew--widget .mapboxgl-marker.klubb {
      background-image: url("/evenemang/uppsala/static/map_default/klubb.svg"); }
    .abew--widget .mapboxgl-marker.klubb-active {
      background-image: url("/evenemang/uppsala/static/map_active/klubb.svg"); }
    .abew--widget .mapboxgl-marker.teater {
      background-image: url("/evenemang/uppsala/static/map_default/teater.svg"); }
    .abew--widget .mapboxgl-marker.teater-active {
      background-image: url("/evenemang/uppsala/static/map_active/teater.svg"); }
    .abew--widget .mapboxgl-marker.festival {
      background-image: url("/evenemang/uppsala/static/map_default/festival.svg"); }
    .abew--widget .mapboxgl-marker.festival-active {
      background-image: url("/evenemang/uppsala/static/map_active/festival.svg"); }
    .abew--widget .mapboxgl-marker.sport {
      background-image: url("/evenemang/uppsala/static/map_default/sport.svg"); }
    .abew--widget .mapboxgl-marker.sport-active {
      background-image: url("/evenemang/uppsala/static/map_active/sport.svg"); }

/* class applies to select element itself, not a wrapper element */
body.events {
  /* Hide arrow icon in IE browsers */
  /* Hover style */
  /* Focus style */
  /* Set options to normal weight */
  /* Support for rtl text, explicit support for Arabic and Hebrew */
  /* Disabled styles */ }
  body.events .abew--select {
    display: block;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    /* useful when width is set to anything other than 100% */
    box-sizing: border-box;
    margin: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
    */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e5e5e5));
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%; }
  body.events .abew--select::-ms-expand {
    display: none; }
  body.events .abew--select:hover {
    border-color: #888;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease; }
  body.events .abew--select:focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    color: #222;
    outline: none; }
  body.events .abew--select option {
    font-weight: normal; }
  body.events *[dir="rtl"] .abew--select,
  body.events :root:lang(ar) .abew--select,
  body.events :root:lang(iw) .abew--select {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em; }
  body.events .abew--select:disabled,
  body.events .abew--select[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e5e5e5));
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); }
  body.events .abew--select:disabled:hover,
  body.events .abew--select[aria-disabled="true"] {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    border-color: #aaa; }

.spinner {
  position: relative;
  bottom: 10px;
  width: 40px;
  margin-left: calc(50% - 20px); }

.embedded .abep--pageheader {
  display: none; }

.embedded .abew--widget {
  margin: 0 auto; }
  .embedded .abew--widget .abew--eventboxes-container {
    max-height: 210px; }
  .embedded .abew--widget.hide-map .abew--eventboxes-container {
    max-height: 410px;
    height: 268px; }
  .embedded .abew--widget .abew--visapakarta {
    display: flex; }
  .embedded .abew--widget .abew--karta {
    display: none; }

.not-embedded .abep--pageheader {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  border-bottom: solid 4px #000000;
  margin-bottom: 20px; }
  .not-embedded .abep--pageheader .abep--back-link {
    cursor: pointer;
    display: flex;
    flex: 1 1;
    align-items: center;
    padding: 0 8px 0 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    background: transparent url("/evenemang/uppsala/static/UI/arrow-left.svg") no-repeat 11px center;
    background-size: 8px; }
    @media only screen and (max-width: 500px) {
      .not-embedded .abep--pageheader .abep--back-link {
        text-indent: -9999px;
        background-size: 11px;
        background-position: 11px 53%; } }
  .not-embedded .abep--pageheader .abep--pageheader-logo {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 45px;
    text-decoration: none; }
  .not-embedded .abep--pageheader img {
    height: 24px; }
  .not-embedded .abep--pageheader .logo-divider {
    color: black;
    font-size: 29px;
    margin: 3px 2px 0 0;
    font-weight: bold;
    text-indent: -9999px;
    width: 3px; }
  .not-embedded .abep--pageheader .last-child {
    padding: 0 8px 0 25px;
    flex: 1 1; }

.not-embedded body.events .abew--widget .abew--eventboxes-container {
  height: calc(100vh - 355px); }

@media only screen and (min-width: 768px) {
  .not-embedded body.events .abew--widget {
    display: flex;
    flex-wrap: wrap; } }

@media only screen and (min-width: 768px) {
  .not-embedded .abew--body {
    width: 40%;
    min-width: 320px;
    order: 2; } }

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
  vertical-align: initial; }

html * {
  box-sizing: border-box; }

a,
a:visited,
a:active {
  color: #0078bd;
  text-decoration: none; }
  a:hover,
  a:visited:hover,
  a:active:hover {
    text-decoration: underline; }

loader-container {
  padding: 24px 0; }

.loader,
.loader:before,
.loader:after {
  border-radius: 100%;
  width: 1.3em;
  height: 1.3em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.loader {
  color: #dd2a30;
  font-size: 13px;
  margin: 0 auto;
  position: relative;
  top: -26px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0; }

.loader:before {
  left: -2em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 2em; }

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em; }
  40% {
    box-shadow: 0 2em 0 0; } }

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em; }
  40% {
    box-shadow: 0 2em 0 0; } }

.hide {
  display: none !important; }

.abep--event-buttons {
  margin-top: 15px;
  margin-bottom: 5px; }

body .button {
  background: white;
  color: black;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  height: 48px;
  border: 1px solid #979797;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  padding: 0 15px;
  cursor: pointer;
  margin-right: 15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  body .button:last-child {
    margin-right: 0; }
  body .button:hover {
    opacity: 0.95; }

body .button-red {
  border-color: transparent;
  background: #57F099;
  color: white; }
  body .button-red .icon {
    -webkit-filter: invert(100%);
            filter: invert(100%); }

body .icon {
  background-size: 24px 24px;
  padding-left: 24px;
  height: 24px;
  margin-right: 7px;
  background-position: center center;
  background-repeat: no-repeat; }

body .icon__calendar {
  background-image: url("/evenemang/uppsala/static/icons/icon__add-event-to-calendar.svg"); }

body .icon__tickets {
  background-image: url("/evenemang/uppsala/static/icons/icon__tickets.svg"); }

body .icon__map-pin {
  background-image: url("/evenemang/uppsala/static/icons/icon__map-pin.svg"); }

body .calendar p {
  font-size: 14px; }

@media only screen and (max-width: 424px) {
  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto; } }

/*PageComponent*/
body.events {
  width: auto;
  height: auto;
  background: unset;
  background-color: #f2f2f2; }
  body.events .abep--page {
    font-family: Verdana, sans-serif;
    margin-bottom: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start; }
    @media only screen and (max-width: 995px) {
      body.events .abep--page {
        flex-direction: column; } }
    body.events .abep--page .abep--pageheader {
      background-color: white;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex: 1 0 auto;
      border-bottom: solid 4px #000000;
      margin-bottom: 20px; }
      body.events .abep--page .abep--pageheader .abep--back-link {
        cursor: pointer;
        display: flex;
        flex: 1 1;
        align-items: center;
        padding: 0 8px 0 25px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        background: transparent url("/evenemang/uppsala/static/UI/arrow-left.svg") no-repeat 11px center;
        background-size: 8px; }
        @media only screen and (max-width: 500px) {
          body.events .abep--page .abep--pageheader .abep--back-link {
            text-indent: -9999px;
            background-size: 11px;
            background-position: 11px 53%; } }
      body.events .abep--page .abep--pageheader .abep--pageheader-logo {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 45px;
        text-decoration: none; }
      body.events .abep--page .abep--pageheader img {
        height: 24px; }
      body.events .abep--page .abep--pageheader .logo-divider {
        color: black;
        font-size: 29px;
        margin: 3px 2px 0 0;
        font-weight: bold;
        text-indent: -9999px;
        width: 3px; }
      body.events .abep--page .abep--pageheader .last-child {
        padding: 0 8px 0 25px;
        flex: 1 1; }
    body.events .abep--page .abep--pageheader {
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        body.events .abep--page .abep--pageheader {
          margin-bottom: 14px; } }
    body.events .abep--page .abep--event {
      width: 100%;
      max-width: 600px;
      margin-right: 20px; }
      @media only screen and (max-width: 995px) {
        body.events .abep--page .abep--event {
          max-width: 800px;
          align-self: center;
          margin-right: unset;
          margin-bottom: 1.3em; } }
    @media only screen and (max-width: 995px) {
      body.events .abep--page .abew--wrapper {
        max-width: 100%;
        align-self: center; } }
    body.events .abep--page .abew--wrapper .abep--pageheader {
      display: none; }
    body.events .abep--page .abew--widget {
      max-width: 375px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921); }
      @media only screen and (max-width: 995px) {
        body.events .abep--page .abew--widget {
          width: 100%;
          max-width: 800px;
          align-self: center; } }
      body.events .abep--page .abew--widget .abew--karta {
        order: unset;
        height: 200px; }
        body.events .abep--page .abew--widget .abew--karta > div,
        body.events .abep--page .abew--widget .abew--karta .mapboxgl-map {
          height: 200px !important; }
      body.events .abep--page .abew--widget .abew--body {
        width: unset;
        order: unset; }
      body.events .abep--page .abew--widget .abew--visapakarta {
        display: flex; }
      @media only screen and (max-width: 767px) {
        body.events .abep--page .abew--widget.hide-map .abew--karta {
          display: block; } }
      body.events .abep--page .abew--widget .abew--eventboxes-container {
        height: 268px;
        overflow: auto; }
    body.events .abep--page .abep--eventheader {
      display: flex;
      background: white;
      max-width: 100%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921);
      flex-direction: column;
      padding-bottom: 10px; }
      body.events .abep--page .abep--eventheader h1 {
        font-weight: bold;
        font-size: 46px;
        line-height: 1.26em;
        letter-spacing: -0.0565em;
        margin: 6px 14px 0; }
        @media only screen and (max-width: 767px) {
          body.events .abep--page .abep--eventheader h1 {
            font-size: 20px; } }
      body.events .abep--page .abep--eventheader img {
        width: 100%;
        height: 100%; }
    body.events .abep--page .abep--organizer-logo {
      padding: 5px 16px 0;
      display: flex;
      font-size: 14px;
      line-height: 1em;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      align-items: center; }
      body.events .abep--page .abep--organizer-logo img {
        height: 31px;
        width: auto;
        margin-right: 8px; }
    body.events .abep--page .abep--eventlocation {
      text-align: center;
      margin-bottom: 15px; }
      body.events .abep--page .abep--eventlocation p {
        font-size: 16px; }
      body.events .abep--page .abep--eventlocation .icon {
        background-size: 13px auto;
        background-position: 0 0;
        display: inline-block;
        margin-bottom: -10px;
        margin-right: 0;
        padding-left: 17px; }
    body.events .abep--page .abep--eventdetails {
      background: white;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      padding: 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921); }
    body.events .abep--page .abep--eventtime {
      display: flex;
      justify-content: center; }
      body.events .abep--page .abep--eventtime p {
        font-size: 12px;
        text-align: center;
        padding: 0 15px;
        min-height: 41px;
        border-right: 1px solid #efefef; }
        body.events .abep--page .abep--eventtime p:last-of-type {
          border-right: 0; }
      body.events .abep--page .abep--eventtime strong {
        display: block;
        margin-bottom: 5px;
        font-size: 16px; }
    body.events .abep--page .abep--event-buttons {
      display: flex;
      justify-content: center; }
      body.events .abep--page .abep--event-buttons .button {
        width: 33%; }
      @media only screen and (max-width: 767px) {
        body.events .abep--page .abep--event-buttons {
          flex-direction: column; }
          body.events .abep--page .abep--event-buttons .button {
            align-self: center;
            width: 100%;
            margin: 0 0 10px;
            max-width: 320px; } }
    body.events .abep--page .abep--eventcategory {
      position: relative;
      display: flex;
      justify-content: space-between; }
    body.events .abep--page .abep--categoryicon {
      position: absolute;
      top: 0;
      right: 0;
      height: 57px;
      width: 57px;
      margin-right: 16px;
      margin-top: 0; }
      body.events .abep--page .abep--categoryicon.abep--categoryicon-noje {
        background: url("/evenemang/uppsala/static/active_state/noje.svg") center center no-repeat;
        background-size: 57px 57px; }
    body.events .abep--page .abep--eventpricelink {
      display: flex;
      margin-left: 16px;
      margin-top: 16px; }
    body.events .abep--page .abep--eventpricelink-amount,
    body.events .abep--page .abep--eventpricelink-site {
      font-family: Verdana;
      font-size: 14px;
      margin: right; }
    body.events .abep--page .abep--eventpricelink-amount {
      margin-right: 31px; }
    body.events .abep--page .abep--eventpricelink-amount span,
    body.events .abep--page .abep--eventpricelink-site span {
      font-size: 18px;
      font-weight: bold;
      display: block; }
    body.events .abep--page .abep--eventpricelink-site a {
      color: #0078bd;
      text-decoration: none;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      body.events .abep--page .abep--eventpricelink-site a:hover {
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        text-decoration: underline; }
    body.events .abep--page .abep--eventtext {
      background: white;
      margin-top: 12px;
      padding: 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921);
      font-family: arial;
      font-size: 16px; }
      body.events .abep--page .abep--eventtext p {
        margin-bottom: 1em; }
      body.events .abep--page .abep--eventtext iframe {
        display: none; }
    body.events .abep--page .abep--organizer-url a {
      font-weight: bold; }
    body.events .abep--page .abep--readmore {
      height: 40px;
      width: calc(100% - 32px);
      margin: 16px 16px 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      font-family: Helvetica Neue;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer; }
      body.events .abep--page .abep--readmore:hover {
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        color: black; }
    body.events .abep--page .abep--ad {
      display: flex;
      flex-direction: column;
      padding: 20px 10px 10px;
      width: 100%;
      min-height: 104px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      text-align: center;
      text-decoration: none;
      justify-content: center;
      background: #dd2b30 url("/evenemang/uppsala/static/bg-ad.svg") right bottom no-repeat;
      background-size: 100%;
      margin-top: 1em; }
      body.events .abep--page .abep--ad .logos {
        margin-top: 12px; }
        body.events .abep--page .abep--ad .logos img {
          height: 24px; }

