// A bunch of resets
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: initial;
}

html * {
  box-sizing: border-box;
}

a,
a:visited,
a:active {
  color: #0078bd;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// Variables

$box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.109921) !default;

// Spinner styling

loader-container {
  padding: 24px 0;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 100%;
  width: 1.3em;
  height: 1.3em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #dd2a30;
  font-size: 13px;
  margin: 0 auto;
  position: relative;
  top: -26px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -2em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 2em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em;
  }
  40% {
    box-shadow: 0 2em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2em 0 -1.4em;
  }
  40% {
    box-shadow: 0 2em 0 0;
  }
}

.hide {
  display: none !important;
}
.abep--event-buttons {
  margin-top: 15px;
  margin-bottom: 5px;
}

body {
  .button {
    background: white;
    color: black;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    height: 48px;
    border: 1px solid #979797;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    padding: 0 15px;
    cursor: pointer;
    margin-right: 15px;
    transition: all 0.2s ease;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: 0.95;
    }
  }
  .button-red {
    border-color: transparent;
    background:#57F099;
    color: white;
    .icon {
      filter: invert(100%);
    }
  }
  .icon {
    background-size: 24px 24px;
    padding-left: 24px;
    height: 24px;
    margin-right: 7px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .icon__calendar {
    background-image: url("/evenemang/uppsala/static/icons/icon__add-event-to-calendar.svg");
  }
  .icon__tickets {
    background-image: url("/evenemang/uppsala/static/icons/icon__tickets.svg");
  }
  .icon__map-pin {
    background-image: url("/evenemang/uppsala/static/icons/icon__map-pin.svg");
  }
  .calendar p {
    font-size: 14px;
  }
}

// This class is allows line breaks in for example long urls.
// Credit: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.dont-break-out {
  @media only screen and (max-width: 424px) {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    //    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

@mixin abep--pageheader {
  .abep--pageheader {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    border-bottom: solid 4px #000000;
    margin-bottom: 20px;

    .abep--back-link {
      cursor: pointer;
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0 8px 0 25px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      text-decoration: none;
      color: black;
      text-transform: uppercase;
      font-weight: bold;
      background: transparent url("/evenemang/uppsala/static/UI/arrow-left.svg") no-repeat 11px center;
      background-size: 8px;
      @media only screen and (max-width: 500px) {
        text-indent: -9999px;
        background-size: 11px;
        background-position: 11px 53%;
      }
    }
    .abep--pageheader-logo {
      display: flex;
      align-items: center;
      padding: 0 8px;
      height: 45px;
      text-decoration: none;
    }
    img {
      height: 24px;
    }
    .logo-divider {
      color: black;
      font-size: 29px;
      margin: 3px 2px 0 0;
      font-weight: bold;
      text-indent: -9999px; // hide forward-slash
      width: 3px;
    }
    .last-child {
      padding: 0 8px 0 25px;
      flex: 1;
    }
  }
}
